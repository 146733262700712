const getPopUpDimensions = () => {
  const dualScreenLeft =
    typeof window.screenLeft !== "undefined" ? window.screenLeft : screen.left;
  const dualScreenTop =
    typeof window.screenTop !== "undefined" ? window.screenTop : screen.top;
  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;
  const popupWinWidth = 450;
  const popupWinHeight = 457;
  const left = width / 2 - popupWinWidth / 2 + dualScreenLeft;
  const top = height / 2 - popupWinHeight / 2 + dualScreenTop;
  return {
    popupWinWidth,
    popupWinHeight,
    left,
    top,
  };
};
export default getPopUpDimensions;
